<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="점검결과"
          :isTitle="true"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :editable="editable && !disabled"
          :data="result.equipmentResultList"
          :gridHeightAuto="true"
          :merge="grid.merge"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable&&!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="minEquipmentTypeCheckItemId"
                ibmTaskTypeCd="ITT0000055"
                ibmTaskUnderTypeCd="ITTU000085"
                :acceptBtnShow="true"
                @imprChange="imprChange"
              >
                <template v-slot:customArea="{ props }">
                  <div class="col-6">
                    <div class="mini-dailog-title">
                      <i class="pe-7s-angle-right-circle"></i>
                      {{$language('점검결과')}}
                    </div>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable && !disabled"
                      label=""
                      name="checkResult"
                      v-model="props.row.checkResult">
                    </c-text>
                  </div>
                  <template v-for="(item) in customReadOnlyCols">
                    <div class="col-12" :key="item.key">
                      <div class="row">
                        <div class="col-6">
                          <div class="mini-dailog-title">
                            <i class="pe-7s-angle-right-circle"></i>
                              {{$language(item.label)}}
                          </div>
                        </div>
                        <div v-if="props.row[item.name]" class="col-12 border-content-dialog">
                          {{props.row[item.name]}}
                        </div>
                        <div v-else class="col-12 border-nocontent-dialog">
                          {{'No Data'}}
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </component>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    result: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentName: '',
        checkStatusCd: '',
        minEquipmentCheckId: '',
        equipmentResultList: [],
        checkDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      tab: 'resultItem',
      grid: {
        merge: [
          { index: 0, colName: "checkItemName" }],
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'judgmentResultTypeCd',
            field: 'judgmentResultTypeCd',
            label: '판정',
            align: 'center',
            style: 'width:110px',
            type: 'select',
            setHeader: true,
            codeGroupCd: 'MIM_CHECK_RESULT_CD',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
          // {
          //   name: 'checkWayName',
          //   field: 'checkWayName',
          //   label: '검사방법',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          // {
          //   name: 'checkResult',
          //   field: 'checkResult',
          //   label: '점검결과',
          //   align: 'left',
          //   style: 'width:120px',
          //   type: 'text',
          //   sortable: true,
          // },
          // {
          //   name: 'decisionBasis',
          //   field: 'decisionBasis',
          //   label: '판정기준',
          //   // type: 'textarea',
          //   style: 'width:200px',
          //   align: 'left',
          //   sortable: true
          // },
          // {
          //   name: 'checkCaution',
          //   field: 'checkCaution',
          //   label: '점검시 안전사항',
          //   style: 'width:200px',
          //   align: 'left',
          //   sortable: true
          // },
          
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      updateMode: false,
      equipmentCdName: '',
    };
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemName'];
    },
    // ReadOnly cols
    customReadOnlyCols() { 
      return [
        {
          key: uid(),
          label: '검사방법', 
          name: 'checkWayName'
        },
        {
          key: uid(),
          label: '판정기준', 
          name: 'decisionBasis'
        },
        {
          key: uid(),
          label: '점검시 안전사항', 
          name: 'checkCaution'
        },
      ]
    },  
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
    disabled() {
      return this.result.checkStatusCd === 'MCSC000015';
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.updateItemUrl = transactionConfig.sop.min.equipment.result.updateItem.url;
      // list setting
    },
    saveItemInspection() {
      if (this.$comm.validTable(this.grid.columns, this.result.equipmentResultList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.result.equipmentResultList, _item => {
              _item.minEquipmentCheckId = this.result.minEquipmentCheckId;
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
